import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { Page } from "../../shared/Page/Page"
import { SEO } from "../../utils/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Helmet from "react-helmet"
import { PageHeader } from "../../shared/PageHeader/PageHeader"
import slugify from "slugify"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { TextButton } from "../../shared/Ui/TextButton/TextButton"
import { H1 } from "../../shared/Ui/Typography/Typography"
import { Link } from "gatsby"
import RelatedPosts from "../../shared/RelatedPosts/RelatedPosts"
import media from "css-in-js-media"

const Layout = styled.div`
    > .article-container {
        display: flex;
        gap: 50rem;
        justify-content: space-between;

        > .bar {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 30rem;
            max-width: 300rem;

            > .author {
                display: flex;
                flex-direction: column;
                padding: 10rem 15rem 20rem;
                border-bottom: 1rem solid ${({ theme }) => theme.borders.light};
                // box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;
                border-radius: 10rem;

                > .top {
                    display: flex;
                    gap: 15rem;
                    align-items: center;

                    .avatar {
                    }

                    > .right {
                        display: flex;
                        flex-direction: column;
                        gap: 3rem;

                        .name {
                            color: ${({ theme }) => theme.text.dark};
                            font-family: "Gilroy-SemiBold", sans-serif;
                            font-size: 16rem;
                        }

                        .title {
                            color: ${({ theme }) => theme.text.normal};
                            font-family: "Gilroy-Regular", sans-serif;
                            font-size: 14rem;
                        }

                        .socials {
                            display: flex;

                            button {
                                display: flex;
                                align-items: center;
                                gap: 3rem;
                                color: ${({ theme }) => theme.text.light};
                                font-size: 12rem;
                            }
                        }
                    }
                }
            }

            .tags {
                > .title {
                    font-size: 14rem;
                    color: ${({ theme }) => theme.text.light};
                    text-transform: uppercase;
                    font-family: "Gilroy-SemiBold", sans-serif;
                }

                > .tags {
                    display: flex;
                    gap: 7rem;
                    flex-wrap: wrap;
                    padding-top: 10rem;

                    * {
                        text-transform: capitalize;
                    }
                }
            }

            .navigation {
                position: sticky;
                top: 40rem;

                > .title {
                    font-size: 14rem;
                    color: ${({ theme }) => theme.text.light};
                    text-transform: uppercase;
                    font-family: "Gilroy-SemiBold", sans-serif;
                }

                ul {
                    list-style: none outside none;
                    padding: 0;

                    li {
                    }
                }
            }
        }

        > .article {
            flex: 1;
            max-width: 650rem;
            text-rendering: optimizeLegibility;
            padding: 0 20rem 100rem;

            .header {
                border-left: 1rem solid ${({ theme }) => theme.surfaces.darker};
                margin: 0 -20rem;
                padding: 0 20rem;
            }

            .article-hero {
                border-radius: 10rem;
                box-shadow: rgb(129, 140, 248, 0.35) 0 8rem 24rem,
                    rgb(96, 165, 250, 0.1) 0 5rem 4rem 0;
                margin: 20rem 0 40rem;
            }

            .date-published {
                font-size: 14rem;
                color: ${({ theme }) => theme.text.lighter};
                font-family: "Gilroy-SemiBold", sans-serif;
            }

            h1 {
                margin: 10rem -21.5rem;
                padding: 0 22rem;
                border-left: 2rem solid ${({ theme }) => theme.secondary};
            }

            h2 {
                font-family: "Gilroy-SemiBold", sans-serif;
                font-size: 24rem;
                color: ${({ theme }) => theme.text.dark};
                margin-top: 80rem;
            }

            h3 {
                font-family: "Gilroy-SemiBold", sans-serif;
                font-size: 20rem;
                color: #3730a3;
                border-left: 2rem solid ${({ theme }) => theme.primary};
                padding-left: 10rem;
            }

            h2,
            h3,
            h4 {
                position: relative;

                &:hover {
                    .anchor.before svg {
                        visibility: visible;
                    }
                }

                .anchor.before {
                    position: absolute;
                    left: -25rem;
                    padding-right: 10rem;

                    svg {
                        visibility: hidden;

                        &:hover {
                            fill: ${({ theme }) => theme.primary};
                        }
                    }
                }
            }

            ol {
                li {
                    color: ${({ theme }) => theme.text.normal};
                    line-height: 30rem;
                    margin-bottom: 15rem;
                    font-size: 18rem;
                }
            }

            a {
                color: ${({ theme }) => theme.primary};

                :hover {
                    text-decoration: underline;
                }
            }

            blockquote {
                font-size: 24rem;
                padding: 80rem 20rem;
                text-align: center;
                font-family: "Merriweather", serif;
                font-style: italic;
                color: ${({ theme }) => theme.text.darker};
            }

            > p {
                color: ${({ theme }) => theme.text.normal};
                line-height: 31rem;
                font-size: 17rem;
                text-rendering: optimizeLegibility;

                &:first-of-type {
                    &:first-letter {
                        font-size: 60rem;
                        font-family: "Gilroy-Black", sans-serif;
                        float: left;
                        padding-right: 15rem;
                        padding-left: 10rem;
                        line-height: 1.2;
                    }
                }

                figure {
                    .gatsby-resp-image-wrapper {
                        width: 100%;
                        border-radius: 10rem;
                        overflow: hidden;
                        //box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    }

                    figcaption {
                        text-align: center;
                        font-size: 16rem;
                        color: ${({ theme }) => theme.text.dark};
                    }
                }
            }
        }

        .tip {
            flex: 0;
            min-width: 300rem;
        }

        .tip-container {
            position: sticky;
            top: 40rem;

            padding: 20rem 25rem;
            border-bottom: 1rem solid ${({ theme }) => theme.borders.light};
            box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;
            border-radius: 10rem;

            display: flex;
            flex-direction: column;
            gap: 10rem;

            margin-bottom: 20rem;

            &.left-copy {
                display: none;
                position: relative;
                top: 0;
            }

            .head {
                background: #eff6ff;
                margin: -20rem -25rem 10rem;
                padding: 20rem 25rem 0;
                border-radius: 10rem 10rem 0 0;
            }

            .callout {
                font-size: 40rem;
            }

            .title {
                font-size: 20rem;
                color: ${({ theme }) => theme.text.light};
                font-family: "Gilroy-SemiBold", sans-serif;
                line-height: 30rem;
            }

            .blurb {
                color: ${({ theme }) => theme.text.lighter};
                font-size: 14rem;
                line-height: 27rem;
            }
        }

        ${media("<largeDesktop")} {
            justify-content: center;

            .tip {
                display: none;
            }

            .tip-container.left-copy {
                display: flex;
            }
        }

        ${media("<tablet")} {
            flex-direction: column-reverse;

            .bar {
                max-width: unset;

                .author {
                    align-items: center;
                }

                .tags {
                    .title {
                        text-align: center;
                    }

                    > .tags {
                        justify-content: center;
                    }
                }

                .tip-container {
                    text-align: center;
                }

                .navigation {
                    display: none;
                }
            }

            .article {
                padding: 0;

                .header {
                    border-left: none !important;

                    h1 {
                        border-left: none;
                    }
                }
            }
        }
    }
`

const shortcodes = { TextButton, FlatButton }

const PostSimple = ({ location, ...props }) => {
    const context = props.pageContext

    const [activeId, setActiveId] = useState(``)

    const itemIds = useMemo(() => {
        return context.tableOfContents.items.reduce((acc, item) => {
            if (item.url) {
                // url has a # as first character, remove it to get the raw CSS-id
                acc.push(item.url.slice(1))
            }
            return acc
        }, [])
    }, [context.tableOfContents])

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveId(entry.target.id)
                    }
                })
            },
            { rootMargin: `0% 0% -50% 0%` }
        )

        itemIds.forEach((id) => {
            const el = document.getElementById(id)

            if (el) {
                observer.observe(document.getElementById(id))
            }
        })

        return () => {
            itemIds.forEach((id) => {
                const el = document.getElementById(id)

                if (el) {
                    observer.unobserve(document.getElementById(id))
                }
            })
        }
    }, [itemIds])

    return (
        <Page constrained location={location}>
            <SEO
                location={location}
                title={context.frontmatter.title}
                description={context.frontmatter.description}
                ogImage={`https://23shout.com${context.frontmatter.ogImageBaseUrl}`}
                article={{
                    title: context.frontmatter.title,
                    description: context.frontmatter.description,
                    slug: context.slug,
                    imageUrl: context.frontmatter.ogImageBaseUrl,
                    datePublished: context.frontmatter.isoDate,
                    dateEdited: context.frontmatter.isoDate,
                    subBlog: context.frontmatter.subblog,
                    author: {
                        "@type": "Person",
                        name: context.frontmatter.author,
                    },
                }}
            />
            <Helmet>
                <meta property="og:type" content="article" />
                <meta property="twitter:type" content="article" />
                <meta property="twitter:site" content="@23shout" />

                <meta property={"twitter:label1"} content={"Blog"} />
                <meta
                    property={"twitter:data1"}
                    content={`${context.frontmatter.subblog}`}
                />
                <meta property={"twitter:label2"} content={"Reading time"} />
                <meta
                    property={"twitter:data2"}
                    content={`${context.timeToRead} mins`}
                />
            </Helmet>
            <PageHeader
                breadCrumbs={[
                    { to: "/blog", title: "Blog" },
                    {
                        to: `/blog/${slugify(context.frontmatter.subblog, {
                            lower: true,
                        })}`,
                        title: `${context.frontmatter.subblog}`,
                    },
                ]}
                actionButtons={[
                    <a
                        href={"https://twitter.com/23shout"}
                        target={"_blank"}
                        tabIndex={-1}
                    >
                        <FlatButton
                            color={"primary"}
                            variant={"outlined"}
                            size={"large"}
                            fontWeight={600}
                        >
                            <StaticImage
                                src={"../../images/blog/twitter.svg"}
                                alt={"Twitter"}
                                layout={"fixed"}
                                width={20}
                                height={20}
                                placeholder={"none"}
                            />
                            {""}
                            <span style={{ marginRight: "5rem" }} />
                            23shout on twitter
                        </FlatButton>
                    </a>,
                ]}
            />
            <Layout>
                <section className={"article-container"}>
                    <div className={"bar"}>
                        <div className={"author"}>
                            <div className={"top"}>
                                <GatsbyImage
                                    image={
                                        context.frontmatter.avatar
                                            .childImageSharp.gatsbyImageData
                                    }
                                    alt={`${context.frontmatter.author}'s photo`}
                                    className={"avatar"}
                                />
                                <div className={"right"}>
                                    <div className={"name"}>
                                        {context.frontmatter.author}
                                    </div>
                                    <div className={"title"}>
                                        {context.frontmatter.authorTitle}
                                    </div>
                                    <div className={"socials"}>
                                        <div className={"twitter"}>
                                            <a
                                                href={`https://twitter.com/${context.frontmatter.authorTwitter}`}
                                                tabIndex={-1}
                                            >
                                                <TextButton>
                                                    <StaticImage
                                                        src={
                                                            "../../images/blog/twitter.svg"
                                                        }
                                                        alt={"Twitter"}
                                                        layout={"fixed"}
                                                        width={25}
                                                        height={25}
                                                        placeholder={"none"}
                                                    />
                                                    @
                                                    {
                                                        context.frontmatter
                                                            .authorTwitter
                                                    }
                                                </TextButton>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"tags"}>
                            <div className={"title"}>Tags</div>
                            <div className={"tags"}>
                                {context.frontmatter.tags.map((tag) => (
                                    // <Link
                                    //     to={`/blog/tag/${slugify(tag)}`}
                                    //     key={tag}
                                    //     tabIndex={-1}
                                    // >
                                    <FlatButton
                                        size={"medium"}
                                        variant={"flat"}
                                        color={"white"}
                                        fontWeight={500}
                                    >
                                        {tag}
                                    </FlatButton>
                                    // </Link>
                                ))}
                            </div>
                        </div>

                        <div className={"tip-container left-copy"}>
                            <div className={"head"}>
                                <StaticImage
                                    src={"../../images/home/hero-small.png"}
                                    alt={"The 23shout experience"}
                                    quality={100}
                                    placeholder={"none"}
                                    width={600}
                                />
                            </div>
                            <div className={"callout"}>
                                <span role={"img"} title={"Waving hand"}>
                                    👋
                                </span>
                            </div>
                            <div className={"title"}>
                                Looking to upgrade your calling experience?
                            </div>
                            <div className={"blurb"}>
                                Speak with our team of experts about
                                supercharging your businesses communications.
                            </div>
                            <Link to={"/support/contact"} tabIndex={-1}>
                                <FlatButton
                                    color={"primary"}
                                    variant={"flat"}
                                    justify={"center"}
                                    size={"large"}
                                    fontWeight={600}
                                    fullWidth
                                >
                                    Contact us now
                                </FlatButton>
                            </Link>
                        </div>

                        <div className={"navigation"}>
                            <div className={"title"}>Contents</div>
                            <ul>
                                {context.tableOfContents.items.map((item) => {
                                    return (
                                        <li key={item.url}>
                                            <a href={item.url} tabIndex={-1}>
                                                <FlatButton
                                                    fullWidth
                                                    size={"large"}
                                                    active={
                                                        item.url.substr(1) ===
                                                        activeId
                                                    }
                                                >
                                                    {item.title.replace(
                                                        "--",
                                                        "▸"
                                                    )}
                                                </FlatButton>
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className={"article"}>
                        <div className={"header"}>
                            <div className={"date-published"}>
                                Published {context.frontmatter.date} -{" "}
                                {context.timeToRead} min read
                            </div>
                            <H1>{context.frontmatter.title}</H1>
                            <GatsbyImage
                                alt={context.frontmatter.headerImageAlt}
                                role={"banner"}
                                aria-label={`Hero image of ${context.frontmatter.headerImageAlt}`}
                                image={
                                    context.frontmatter.headerImage
                                        .childImageSharp.gatsbyImageData
                                }
                                className={"article-hero"}
                            />
                        </div>
                        <MDXRenderer children={context.body} />
                    </div>
                    <div className={"tip"}>
                        <div className={"tip-container"}>
                            <div className={"head"}>
                                <StaticImage
                                    src={"../../images/home/hero-small.png"}
                                    alt={"The 23shout experience"}
                                    quality={100}
                                    placeholder={"none"}
                                    width={600}
                                />
                            </div>
                            <div className={"callout"}>
                                <span role={"img"} title={"Waving hand"}>
                                    👋
                                </span>
                            </div>
                            <div className={"title"}>
                                Looking to upgrade your calling experience?
                            </div>
                            <div className={"blurb"}>
                                Speak with our team of experts about
                                supercharging your businesses communications.
                            </div>
                            <Link to={"/support/contact"} tabIndex={-1}>
                                <FlatButton
                                    color={"primary"}
                                    variant={"flat"}
                                    justify={"center"}
                                    size={"large"}
                                    fontWeight={600}
                                    fullWidth
                                >
                                    Contact us now
                                </FlatButton>
                            </Link>
                        </div>
                    </div>
                </section>
                <RelatedPosts
                    id={context.id}
                    subblog={context.frontmatter.subblog}
                    tags={context.frontmatter.tags}
                />
            </Layout>
        </Page>
    )
}

export default PostSimple
