import React, { useMemo } from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import media from "css-in-js-media"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20rem;
    padding-bottom: 100rem;
    margin-top: 80rem;

    > .related-title {
        font-size: 26rem;
        color: ${({ theme }) => theme.text.normal};
        text-align: center;
        font-family: "Gilroy-SemiBold", sans-serif;
        border-bottom: 1rem solid ${({ theme }) => theme.borders.light};
        padding-bottom: 20rem;

        span {
            color: ${({ theme }) => theme.primary};
        }
    }

    > div.posts {
        display: flex;
        gap: 20rem;
        flex-wrap: wrap;
        justify-content: center;

        > * {
            flex: 1;
            max-width: 300rem;
            display: flex;
            flex-direction: column;
            gap: 10rem;
            text-align: left;
            background: #fff;
            box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;
            border-radius: 10rem;
            padding: 10rem 10rem;

            &:hover {
                .image {
                    filter: grayscale(0);
                }
            }

            .image {
                overflow: hidden;
                transition: all linear 100ms;
                border-radius: 10rem 10rem 0 0;
                margin: -10rem -10rem 0rem;
            }

            .title {
                color: ${({ theme }) => theme.text.normal};
                font-family: "Gilroy-SemiBold", sans-serif;
                font-size: 20rem;
                padding: 10rem 10rem 0;
                line-height: 30rem;
            }

            .description {
                color: ${({ theme }) => theme.text.lighter};
                font-size: 13rem;
                line-height: 20rem;
                padding: 0 10rem 10rem;
            }
        }

        ${media("<tablet")} {
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;
        }
    }
`

interface RelatedPosts {
    id: string
    subblog: string
    tags: string[]
}

const tagMatcherCount = (needles: string[], haystack: string[]) => {
    return haystack.reduce((previousValue, currentValue) => {
        return needles.includes(currentValue)
            ? [...previousValue, currentValue]
            : previousValue
    }, []).length
}

const RelatedPosts = ({ id, subblog, tags }: RelatedPosts) => {
    const query = useStaticQuery(graphql`
        query MyQuery {
            allMdx(filter: { frontmatter: { type: { eq: "blog" } } }) {
                edges {
                    node {
                        id
                        slug
                        timeToRead
                        frontmatter {
                            tags
                            description
                            subblog
                            title
                            headerImage {
                                childImageSharp {
                                    gatsbyImageData(width: 200, height: 200)
                                }
                            }
                            headerImageAlt
                        }
                    }
                }
            }
        }
    `)

    const rankedPosts = useMemo(() => {
        return [...query.allMdx.edges]
            .sort((a, b) => {
                return -(
                    tagMatcherCount(tags, a.node.frontmatter.tags) <
                    tagMatcherCount(tags, b.node.frontmatter.tags)
                )
            })
            .slice(0, 3)
            .filter((p) => p.node.id !== id)
    }, [query])

    if (rankedPosts.length < 3) {
        return null
    }

    return (
        <Container>
            <div className={"related-title"}>
                More from <span>the blog</span>...
            </div>
            <div className={"posts"}>
                {rankedPosts.map((p) => {
                    return (
                        <Link to={`/${p.node.slug}`}>
                            <GatsbyImage
                                alt={p.node.frontmatter.headerImageAlt}
                                image={
                                    p.node.frontmatter.headerImage
                                        .childImageSharp.gatsbyImageData
                                }
                                className={"image"}
                            />
                            <div className={"title"}>
                                {p.node.frontmatter.title}
                            </div>
                            <div className={"description"}>
                                {p.node.timeToRead} min read
                            </div>
                            {/*<div className={"description"}>*/}
                            {/*    {p.node.frontmatter.description}*/}
                            {/*    /!*{p.node.timeToRead} min read*!/*/}
                            {/*</div>*/}
                        </Link>
                    )
                })}
            </div>
        </Container>
    )
}

export default RelatedPosts
